<template>
    <div class="user-autocomplete">
        <md-autocomplete :filterList="userFilter" :list="users" :print-attribute="field" v-model="innerValue"></md-autocomplete>
    </div>
</template>
<script>
import Autocomplete from "@/components/Autocomplete";
// todo support email and all users
export default {
    props: {
        value: {
            type: String
        },
        field: {
            type: String, //showing field, can be name or email
            default: "name"
        },
        role: {
            type: String
        },
        vendor_id: {
            type: String // used when role == staff
        }
    },
    data() {
        return {
            innerValue: undefined,
            users: [{}],
            user: null
        };
    },

    watch: {
        innerValue: {
            async handler() {
                if (this.innerValue) {
                    console.info("user-autocomplete innervalue changed", this.innerValue);
                    this.$emit("innerValueChanged", this.innerValue);

                    let user = this.users.find(u => u[this.field] == this.innerValue);
                    if (!user) {
                        await this.fetch({ q: this.innerValue });
                        user = this.users.find(u => u[this.field] == this.innerValue);
                    }

                    if ((this.user && !user) || (!this.user && user) || (this.user && user && this.user._id != user._id)) {
                        this.user = user;
                        this.$emit("userChanged", this.user);
                        console.info("user-autocomplete userchanged", this.user);
                    }
                    console.info("user-autocomplete user", user);
                    if (user) {
                        this.$emit("input", user._id);
                    } else {
                        this.$emit("input", undefined);
                    }
                }
            }
        },
        value: {
            immediate: true,
            async handler() {
                if (this.value) {
                    // todo move this to fetch or add a debounce
                    console.info("user-autocomplete value changed", this.value);
                    let user = this.users.find(u => u._id == this.value);
                    if (!user && this.user && this.user._id == this.value) {
                        user = this.user;
                    }
                    if (!user) {
                        user = await this.getUser(this.value);
                    }

                    this.innerValue = user && user[this.field];
                }
            }
        }
    },
    methods: {
        async fetch(cond) {
            let api = "users";
            switch (this.role) {
                case "admin":
                    api = "users/admins";
                    break;
                case "vendor":
                    api = "users/vendors";
                    break;
                case "staff":
                    api = "users/staffs";
                    break;
                case "user":
                    api = "users/customers";
                    break;
                default:
                    api = "users";
                    break;
            }

            let result = await this.$store.dispatch("crud/get", {
                api,
                params: {
                    staff_vendor_id: this.vendor_id,
                    ...cond
                }
            });
            let users = result.data || result;
            this.users = users;

            return result.data || result;
        },
        async getUser(id) {
            console.info("user-autocomplete  getUser", id);
            let result = await this.$store.dispatch("crud/get", {
                api: `users/${id}`
            });
            return result;
        },
        userFilter(list, query) {
            return list;
        }
    }
};
</script>

<style lang="less">
.user-autocomplete {
    flex: 1;
    width: 100%;
}
</style>
